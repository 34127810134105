.hero-container {
  margin: 2rem;
  width: 85%;
  background-color: var(--primary-white);
  padding: 0 2.25rem;
  overflow: hidden;
}

.title-container {
  margin-bottom: 2rem;
}

.title {
  font-weight: 700 !important;
  font-size: 26px !important;
  color: var(--light-grey) !important;
  font-family: Poppins, sans-serif !important;
}

.sub-title {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: var(--primary-grey);
  font-family: Poppins, sans-serif !important;
}

.preview-content {
  width: 100%;
  background-color: var(--lighter-white);
  padding: 0rem 2.25rem 2.25rem 2.25rem;
  display: flex;
  flex-direction: column;
}

.button-container,
.preview-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.preview-button-container {
  margin: 0 !important;

}

.template-container {
  display: flex;
  justify-content: space-around;
}

.preview-button {
  /* margin-bottom: 0.5rem; */
  margin: 26px 0px;
  align-self: flex-end !important;
}

.container-to-print {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 0;
  flex-direction: column;
}

#container-to-print {
  overflow: auto;
}

.preview-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.preview-container {
  width: 100%;
  background-color: var(--lighter-white);
  padding: 0.5rem 2.25rem 2.25rem 2.25rem;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1025px) {
  .buttonToHide {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .preview-content {
    display: none;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .buttonToHide {
    text-align: right;
  }

  .container-to-print {
    width: 85%;
  }

  .title {
    font-size: 24px !important;
  }

  .sub-title {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 22px !important;
  }

  .sub-title {
    font-size: 14px !important;
  }

  .label {
    font-size: 16px !important;
  }

  .container-to-print {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 20px !important;
  }

  .sub-title {
    font-size: 13px !important;
  }

  .label {
    font-size: 14px !important;
  }

  .container-to-print {
    width: 95%;
  }
}
@media screen and (max-width: 550px) {
  .hero-container {
    margin: 2rem 0px;
  }
  .title {
    font-size: 20px !important;
  }

  .sub-title {
    font-size: 12px !important;
  }

  .label {
    font-size: 13px !important;
  }

  .container-to-print {
    width: 95%;
  }

  .preview-container {
    padding: 0px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 18px !important;
  }

  .sub-title {
    font-size: 11px !important;
  }

  .label {
    font-size: 12px !important;
  }
}

/* //Date Picker */

input[type="date"] {
  position: relative;
  cursor: pointer;
  align-items: center;
}

input[type="date"]:after {
  content: "";
  background-image: url("/src/Assets/calendar.png");
  height: 25px;
  width: 25px;
  background-size: contain;
  padding-top: 5px;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
  cursor: pointer;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: 1;
  cursor: pointer;
}
