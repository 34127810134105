.form-control {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none;
  border: transparent;
}

.search-box {
  width: 100%;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
}

.input-group {
  flex-wrap: nowrap !important;
}

.search-icon {
  color: #e36456;
  font-size: 28px;
}

.clear-icon {
  color: rgb(87, 82, 82);
  font-size: 24px;
}

.search-div {
  position: relative;
}

.result-div {
  margin-top: 20px;
  position: absolute;
  width: 100%;
  background: #f8f9fa;
}

.result-div p {
  margin-bottom: 0 !important;
}
.filter-para {
  padding: 5px 20px;
  border-radius: 5px;
  padding-left: 20px;
  cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .filter-para p {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {
  .filter-para p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .filter-para p {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .filter-para p {
    font-size: 12.5px;
  }
}
