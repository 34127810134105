.navbar {
  font-size: 1rem;
  border-radius: 2px;
  background-color: #f8f8f8;
  max-width: 100%;
  margin: auto;
  text-align: center;
  /* padding: 0px; */
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);

}

.nav-link {
  color: black;
  text-align: left;
}

.nav-link {
  transition: none !important;
}

.nav-link:hover {
  color: rgb(179, 171, 171);
}

.logo > img {
  width: 5rem;
  cursor: pointer;
}

.navbar-brand {
  color: white;
  padding-top: 0px !important;
}

.navbar-brand:hover {
  color: rgb(179, 171, 171);
}

.navbar-nav .dropdown-menu {
  width: 14rem;
}

.navbar-nav .nav-link.active {
  color: #ffffff !important;
  border: 2px solid #e36456;
  border-radius: 50px;
  margin-right: 10px;
  background-color: #e36456;
}

.dropdown-menu {
  border: none;
}

.dropdown-divider {
  display: none;
}

.dropdown-item {
  width: fit-content;
}
.dropdown-item:hover {
  background: #e36456;
  border-radius: 50px;
  color: white;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 992px) {
  .navbar {
    font-size: 1rem;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background-color: #f8f8f8;
    max-width: 100%;
    margin: auto;
    /* text-align: center; */
  }

  .nav-link {
    max-width: fit-content;
    padding: 8px 18px;
  }
}

@media screen and (max-width : 560px) {

  .ms-auto {
    margin-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    width: 12rem;
    position: absolute;
    font-size: 12px;
  }

  .nav-link {
    font-size: 12px;
  }
}