.submit-button {
  background: #e36456;
  border: #e36456;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 2px;
  padding: 5px 20px;
}

@media screen and (max-width : 992px) {

  .submit-button {
    font-size: 14px;
  }
  
}

@media screen and (max-width : 768px) {

  .submit-button {
    font-size: 12px;
  }
  
}

@media screen and (max-width : 560px) {

  .submit-button {
    font-size: 10px;
  }
  
}