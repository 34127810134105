.exp-letter-container {
  background-color: var(--primary-white);
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-title {
  font-size: 1rem;
  color: var(--black);
  background-color: transparent;
  font-family: Open Sans, serif;
  font-weight: 700;
  margin-top: 1rem;
  text-decoration: underline;
}

.top-container,
.bottom-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.main-title{
  align-self: center;
}

.paragraph-content,
.bold-text {
  margin: 0;
  font-size: 0.875rem;
  color: var(--black);
  font-family: Open Sans, serif;
  font-weight: 400 !important;
  text-align: justify;
  text-justify: inter-word;
}

.paragraph-text {
  margin: 0;
  padding: 0;
  line-height: 1.1;
  font-size: 0.8rem;
  color: var(--black);
  font-family: Open Sans, serif;
  font-weight: 400 !important;
}

.paragraph-container {
  margin-bottom: 1rem;
}

.outer-container {
  background-color: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.responsive-container {
  width: 800px;
}

.text,
.bold-text,
.text-highlighted {
  font-size: 0.875rem;
  color: var(--black);
  font-family: Open Sans, serif;
  font-weight: 400;
}

.bold-text {
  font-weight: 700 !important;
}

.email-text-highlighted {
  background-color: var(--light-yellow);
}

.email-text,
.email-text-highlighted {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: 400 !important;
}

.text-highlighted {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}

#divToPrint {
  align-self: center;
}

.sign-container {
  width: 7.5rem;
  min-height: 3rem;
  margin: 0.5rem 0 !important;
}

.logo-img {
  align-self: flex-end;
  width: 7.5rem;
  min-height: 3rem;
}

.letter-container {
  background-color: var(--primary-white);
  padding: 2.25rem;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.text-with-margin{
  margin: 0.5rem 0 0 0;
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.header-icons>img {
  width: 18px;
}

.headers> a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.headers {
  border-left: 4px solid #ef973f;
  padding: 10px;
  font-size: 11px;
  margin-left: 16px;
}