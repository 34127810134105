.offer-block {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.offer-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-page {
  width: 100vw;
  height: calc(100vh - 5.8rem);
}

.offer-img {
  width: 100%;
}

.second-half {
  padding-right: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.offer-tag {
  margin-top: 15vh;
  width: 75%;
  margin-left: 10vh;
}

.offer-title {
  font-size: 4rem;
}

.offer-para {
  margin-top: 20px;
  font-size: 16px;
}

.search-div {
  margin-top: 50px;
}

/* text switcher */
#spin {
  color: var(--primary-orange);
}

#spin::before {
  content: "";
  animation: animate infinite 10s;
}

@keyframes animate {
  0% {
    content: "Offer";
  }

  30% {
    content: "Experience";
  }

  60% {
    content: "Appointment";
  }

  100% {
    content: "Confirmation";
  }
}

.offer-title {
  font-size: 2.3rem;
}

.offer-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0 0 0;
}

.first-half {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-div {
  margin-top: 0;
}

.offer-para {
  font-size: 0.8rem;
  margin: 1rem 0 !important;
}

.row {
  --bs-gutter-x: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* =========================== */
@media screen and (max-width: 320px) {
  .offer-title {
    font-size: 2.3rem;
  }

  .offer-tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0 0 0;
  }

  .first-half {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-div {
    margin-top: 0;
  }

  .offer-para {
    font-size: 0.8rem;
    margin: 1rem 0 !important;
  }

  .row {
    --bs-gutter-x: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .offer-title {
    font-size: 2.5rem !important;
  }
  .offer-para {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .offer-title {
    font-size: 3.5rem !important;
  }
  .offer-para {
    font-size: 1.1rem !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .offer-title {
    font-size: 3.7rem !important;
  }
  .offer-para {
    font-size: 1.2rem !important;
  }
}

@media screen and (min-width: 1441px) {
  .offer-title {
    font-size: 4.5rem !important;
  }
  .offer-para {
    font-size: 1.6rem !important;
  }
}

@media screen and (max-width: 560px) {
  .second-half {
    display: none !important;
  }
}
