table {
  width: 100%;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  margin-bottom: 3rem;
  font-family: "Open Sans", sans-serif;
}

tbody td {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

table thead {
  background-color: #f8f8f8;
  text-align: center;
}


td:not(:first-child){
  text-align: center;
}