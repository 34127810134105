.forminput-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-black);
  margin-bottom: 2rem;
  border-radius: 2px;
}

.forminput-container:hover {
  border: 1px solid var(--light-blue);
  box-shadow: var(--dark-blue) 0px 0px 0px 2px;
}

.input,
.label {
  font-family: Poppins, sans-serif !important;
  padding: 0.75rem;
}

.input {
  color: var(--primary-black);
  font-size: 0.875rem;
}

.label {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: var(--light-grey) !important;
}

.input {
  font-weight: 400 !important;
  font-size: 14px !important;
  border: none;
  border-top: 1px solid var(--light-black);
  outline: none;
  width: 100%;
}

.input-div-container{
  display: flex;
}

.clear-button{
  outline: none;
  border: none;
  background: none;
}

.hide-input {
  display: none;
}

.input-label {
  background-color: #f0f0f0;
  padding: 0.1rem 0.5rem;
  border-radius: 2px;
  border: 1px solid rgb(79, 79, 79);
  width: fit-content;
  min-width: 6.8rem;
}

.input-label:hover{
  background-color: rgb(229, 229, 229);
}

.file-input-style {
  padding: 0.75rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--light-black);
}

.filename {
  margin-left: 0.2rem;
  white-space: nowrap; 
  width: 60%; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.file-label-div {
  display: flex;
  width: 90%;
  align-items: center;
}