@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

.exp-letter-container {
  background-color: var(--primary-white);
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 95%;
}

.main-title {
  font-size: 1rem;
  color: var(--black);
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-top: 1rem;
  text-decoration: underline;
}

.top-container,
.bottom-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.main-title{
  align-self: center;
}


.paragraph-content,
.bold-text {
  margin: 0;
  font-size: 0.875rem;
  color: var(--black);
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  text-justify: inter-word;
}

/* .paragraph-text {
  margin: 0;
  padding: 0;
  line-height: 1.1;
  font-size: 0.8rem;
  color: var(--black);
  font-family: "Open Sans", sans-serif;
  font-weight: 400 !important;
} */

.paragraph-container {
  margin-bottom: 1rem;
}

.outer-container {
  background-color: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.text,
.bold-text,
.text-highlighted {
  font-size: 0.875rem;
  color: var(--black);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.bold {
  font-weight: bold !important;
}

.email-text-highlighted {
  background-color: var(--light-yellow);
}

.email-text,
.email-text-highlighted {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: 400 !important;
}

.text-highlighted {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}

#divToPrint {
  align-self: center;
}

.sign-container {
  width: 7.5rem;
  min-height: 3rem;
  margin: 0.5rem 0 !important;
}

.logo {
  align-self: flex-end;
}

.logo {
  text-align: right;
}

ul {
  list-style-type: decimal;
}

.table-container {
  margin-top : 20rem
}

/* confirmation letter styles */
.align-right {
  text-align: end;
}
.note {
  text-align: start;
}

.declaration-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.confirm-title {
  text-decoration: underline;
}

/* appointment letter styles */

.appoint-title {
  text-align: center;
  text-decoration: underline;
}

ol.c {
  list-style-type: upper-roman;
}
